import * as React from "react"
import { graphql } from 'gatsby'

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// COmponents
import { Page } from 'components/core/layouts/base';
import { OfferList } from 'components/careers/content';
import { Hero } from 'components/careers/hero';
import { Footer } from 'components/core/footer';

function IndexPage({data, location}){
  const {t} = useTranslation("careers");
  return <Page dark={false} location={location} title={t("metadata.title")} description={t("metadata.description")}>
          <Hero/>
          <OfferList values={data.allMdx.edges}/>
          <Footer/>
          </Page>
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMdx(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {fields: {source: {eq: "careers"}, lang: {eq: $language}}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            stay
            location
          }
          fields {
            source
            slug
            path
          }
        }
      }
    }
  }
`;